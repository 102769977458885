import { render, staticRenderFns } from "./TagPicker.vue?vue&type=template&id=07a65257&"
import script from "./TagPicker.vue?vue&type=script&lang=js&"
export * from "./TagPicker.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QSelect from 'quasar/src/components/select/QSelect.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QSelect,QIcon,QBtn,QItem,QItemSection,QItemLabel,QSpinner,QChip});
