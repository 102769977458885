import feathersClient, {makeServicePlugin, BaseModel} from '../../api/feathers-client';
import {diff} from '@iy4u/common-client-lib';

class InventoryItems extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'InventoryItems';

  static diffOnPatch(data) {
    if (data['_id']) {
      const originalObject = InventoryItems.store.state['inventory-items'].keyedById[data['_id']];
      return diff(originalObject, data);
    } else {
      return data;
    }
  }

  // Define default properties here
  static instanceDefaults() {
    return {
      countInventoryItems: [],
      inventory: undefined,
      product: undefined,
      quantity: undefined,
      _type: undefined,
      quantityDate: undefined,
      counts: [],
    };
  }
}

const servicePath = 'inventory-items';
const servicePlugin = makeServicePlugin({
  Model: InventoryItems,
  service: feathersClient.service(servicePath),
  servicePath,
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
