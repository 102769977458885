import { render, staticRenderFns } from "./GroupFieldsExpansion.vue?vue&type=template&id=5288f571&scoped=true&"
import script from "./GroupFieldsExpansion.vue?vue&type=script&lang=js&"
export * from "./GroupFieldsExpansion.vue?vue&type=script&lang=js&"
import style0 from "./_GroupFieldsExpansion.scss?vue&type=style&index=0&id=5288f571&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5288f571",
  null
  
)

export default component.exports
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QExpansionItem,QCard,QCardSection});
