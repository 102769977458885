// eslint-disable-next-line no-unused-vars
export default function ({store, ssrContext}) {
  const routes = [
    {
      path: '/',
      component: () => import('layouts/CommerceLayout.vue'),
      children: [
        // {
        //   path: '',
        //   name: 'home',
        //   beforeEnter(to, from, next) {
        //     if (store.getters['auth/user']) {
        //       next({name: 'dashboard'});
        //     } else {
        //       next();
        //     }
        //   },
        //   component: () => import('pages/Index.vue'),
        // },
        {
          path: '',
          name: 'home',
          component: () => import('pages/Commerce/Home.vue'),
        },
        {
          path: 'brands',
          name: 'brands',
          component: () => import('pages/Commerce/Brands.vue'),
        },
        {
          path: 'about',
          name: 'about',
          component: () => import('pages/Commerce/About.vue'),
        },
        {
          path: 'contact',
          name: 'contact',
          component: () => import('pages/Commerce/Contact.vue'),
        },
        {
          path: 'amazon-account-management',
          name: 'amazon-account-management',
          component: () => import('pages/Commerce/AmazonAccountManagement'),
        },
        {
          path: 'amazon-account-management/amazon-seller-central-setup',
          name: 'amazon-seller-central-setup',
          component: () => import('pages/Commerce/AmazonSellerCentralSetup'),
        },
        {
          path: 'amazon-account-management/listing-optimization',
          name: 'listing-optimization',
          component: () => import('pages/Commerce/ListingOptimization'),
        },
        {
          path: 'amazon-account-management/brand-registry-and-protection',
          name: 'brand-registry-and-protection',
          component: () => import('pages/Commerce/BrandRegistryAndProtection'),
        },
        {
          path: 'amazon-account-management/vendor-central-setup',
          name: 'vendor-central-setup',
          component: () => import('pages/Commerce/VendorCentralSetup'),
        },
        {
          path: 'amazon-account-management/amazon-store-front',
          name: 'amazon-store-front',
          component: () => import('pages/Commerce/AmazonStoreFront'),
        },
        {
          path: 'amazon-account-management/amazon-review-management',
          name: 'amazon-review-management',
          component: () => import('pages/Commerce/AmazonReviewManagement'),
        },
        {
          path: 'amazon-account-management/campaign-creation-and-management',
          name: 'campaign-creation-and-management',
          component: () => import('pages/Commerce/CampaignCreationAndManagement'),
        },
        {
          path: 'amazon-account-management/amazon-case-and-management',
          name: 'amazon-case-and-management',
          component: () => import('pages/Commerce/AmazonCaseAndManagement'),
        },
        {
          path: 'brand-protection',
          name: 'brand-protection',
          component: () => import('pages/Commerce/BrandProtection'),
        },
        {
          path: 'brand-protection/daily-brand-monitoring',
          name: 'daily-brand-monitoring',
          component: () => import('pages/Commerce/DailyBrandMonitoring'),
        },
        {
          path: 'brand-protection/consolidate-sellers',
          name: 'consolidate-sellers',
          component: () => import('pages/Commerce/ConsolidateSellers'),
        },
        {
          path: 'brand-protection/register-ip-complaints',
          name: 'register-ip-complaints',
          component: () => import('pages/Commerce/RegisterIpComplaints'),
        },
        {
          path: 'sales-performance',
          name: 'sales-performance',
          component: () => import('pages/Commerce/SalesPerformance'),
        },
        {
          path: 'sales-performance/seo-strategies',
          name: 'seo-strategies',
          component: () => import('pages/Commerce/SeoStrategies'),
        },
        {
          path: 'sales-performance/ppc-campaigns',
          name: 'ppc-campaigns',
          component: () => import('pages/Commerce/PpcCampaigns'),
        },
        {
          path: 'retail-partners',
          name: 'retail-partners',
          component: () => import('pages/Commerce/RetailPartners'),
        },
        {
          path: 'sales-channels',
          name: 'sales-channels',
          component: () => import('pages/Commerce/SalesChannels'),
        },
        {
          path: 'sales-channels/facebook-marketplace',
          name: 'facebook-marketplace',
          component: () => import('pages/Commerce/FacebookMarketplace'),
        },
        {
          path: 'sales-channels/ebay-marketplace',
          name: 'ebay-marketplace',
          component: () => import('pages/Commerce/EbayMarketplace'),
        },
        {
          path: 'sales-channels/amazon',
          name: 'amazon',
          component: () => import('pages/Commerce/Amazon'),
        },
        {
          path: 'sales-channels/target-plus-marketplace',
          name: 'target-plus-marketplace',
          component: () => import('pages/Commerce/TargetPlusMarketplace'),
        },
        {
          path: 'sales-channels/walmart-marketplace',
          name: 'walmart-marketplace',
          component: () => import('pages/Commerce/WalmartMarketplace'),
        },
        {
          path: 'sales-channels/brick-and-mortar',
          name: 'brick-and-mortar',
          component: () => import('pages/Commerce/BrickAndMortar'),
        },
        {
          path: 'sales-channels/instagram',
          name: 'instagram',
          component: () => import('pages/Commerce/Instagram'),
        },
        {
          path: 'careers',
          name: 'careers',
          component: () => import('pages/Commerce/Careers/Careers'),
        },
        {
          path: 'careers/open-:type-positions',
          name: 'open-positions',
          component: () => import('pages/Commerce/Careers/OpenPositions'),
        },
        {
          path: 'leadership-team',
          name: 'leadership-team',
          component: () => import('pages/Commerce/LeadershipTeam'),
        },
        {
          path: 'coming-soon',
          name: 'coming-soon',
          component: () => import('pages/ComingSoonPage/ComingSoonPage'),
        },

        {
          path: 'dashboard',
          name: 'dashboard',
          beforeEnter(to, from, next) {
            if (!store.getters['auth/user']) {
              next({name: 'home'});
            } else {
              next();
            }
          },
          component: () => import('pages/Dashboard'),
        },

        {
          path: 'msg',
          name: 'msg',
          meta: {requiresAuth: true},
          component: () => import ('components/messages.vue'),
        },
        {
          path: 'balance-sheets',
          name: 'balance-sheets',
          meta: {requiresAuth: true},
          component: () => import('pages/qb_reports/BalancedSheets'),
        },
        {
          path: 'profit-and-loss',
          name: 'profit-and-loss',
          meta: {requiresAuth: true},
          component: () => import('pages/qb_reports/ProfitAndLoss'),
        },
        {
          path: 'notifications',
          name: 'notifications',
          meta: {requiresAuth: true},
          component: () => import ('pages/notifications/Notifications.vue'),
        },
        {
          path: 'my-account/:id?',
          name: 'my-account',
          meta: {requiresAuth: true},
          // beforeEnter(to, from, next) {
          //   if (!store.getters['auth/user']) {
          //     next({name: 'home'});
          //   } else {
          //     next();
          //   }
          // },
          component: () => import('pages/profile/AccountProfile/AccountProfile'),
        },
        {
          path: 'quickbooks-connect',
          name: 'quickbooks-connect',
          meta: {requiresAuth: true},
          component: () => import ('pages/ConnectQuickbooks.vue'),
        },


        {
          path: 'messages',
          name: 'messages',
          meta: {requiresAuth: true},
          component: () => import ('pages/messages/messages.vue'),
        },
        {
          path: 'chats',
          name: 'chats',
          meta: {requiresAuth: true},
          component: () => import ('pages/chats/chats.vue'),
        },

        {
          path: 'boards',
          name: 'boards',
          meta: {requiresAuth: true},
          component: () => import ('pages/taskManager/boards'),
        },
        {
          path: 'boards/:id',
          name: 'boardsProcess',
          meta: {requiresAuth: true},
          component: () => import ('pages/taskManager/boards'),
        },
        {
          path: 'boards/:board_id/lists',
          name: 'lists',
          meta: {requiresAuth: true},
          component: () => import('pages/taskManager/lists.vue'),
        },

        {
          path: 'login',
          name: 'login',
          // eslint-disable-next-line no-unused-vars
          props(route) {
            return {
              attrs: {
                fieldsColor: 'secondary',
                redirectUrl: {
                  name: 'home',
                },
                'login-btn-attrs': {
                  class: ['shadow-5'],
                  color: 'primary',
                  'no-caps': false,
                },
                'forgot-password-attrs': {
                  'btn-attrs': {
                    color: 'accent',
                    'no-caps': false,
                  },
                },
                'title-attrs': {
                  class: 'text-uppercase',
                  style: 'font-size: 25px; font-weight: 700;',
                },
              },
              'page-attrs': {
                style: {
                  background: 'radial-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .5))',
                  'background-size': 'cover',
                  '-o-background-size': 'cover',
                  '-moz-background-size': 'cover',
                  '-webkit-background-size': 'cover',
                },
              },
            };
          },
          component: () => import('@ionrev/quasar-app-extension-ir-auth-management-client/src/pages/login/baseLogin/login'),
        },
        {
          path: 'register',
          name: 'register',
          // eslint-disable-next-line no-unused-vars
          props(route) {
            return {
              attrs: {
                fieldsColor: 'secondary',
                default_verify_value: ['sms'],
                'title-attrs': {
                  class: 'text-uppercase',
                  style: 'font-size: 25px; font-weight: 700;',
                },
                'register-btn-attrs': {
                  class: ['shadow-5'],
                  color: 'primary',
                  'no-caps': false,
                },
                'verify-dialog-title-attrs': {
                  class: 'bg-primary text-accent text-uppercase q-mb-sm',
                },
                'sentToMessage-attrs': {
                  class: 'text-weight-bold text-accent',
                  style: 'display: inline-block;',
                },
                'sentToMessage-div-attrs': {
                  class: 'text-uppercase',
                  style: 'font-size: 25px;',
                },
              },
              'page-attrs': {
                style: {
                  background: 'radial-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .5))',
                  'background-size': 'cover',
                  '-o-background-size': 'cover',
                  '-moz-background-size': 'cover',
                  '-webkit-background-size': 'cover',
                },
              },
            };
          },
          component: () => import('@ionrev/quasar-app-extension-ir-auth-management-client/src/pages/register/baseRegister/register'),
        },
        {
          path: 'verify',
          name: 'verify',
          component: () => import('@ionrev/quasar-app-extension-ir-auth-management-client/src/pages/authManagement/verifyEmail/verifyEmail'),
        },
        {
          path: 'resetPassword',
          name: 'resetPassword',
          component: () => import('@ionrev/quasar-app-extension-ir-auth-management-client/src/pages/authManagement/resetPassword/resetPassword'),
        },
        {
          path: 'verifyAndSetPassword',
          name: 'verifyAndSetPassword',
          component: () => import('@ionrev/quasar-app-extension-ir-auth-management-client/src/pages/authManagement/verifyAndSetPassword/verifyAndSetPassword'),
        },
        {
          path: 'changePassword',
          name: 'changePassword',
          component: () => import('@ionrev/quasar-app-extension-ir-auth-management-client/src/pages/authManagement/changePassword/changePassword'),
        },

        {
          path: 'logout',
          name: 'logout',
          beforeEnter(to, from, next) {
            store.dispatch('auth/logout')
              // eslint-disable-next-line no-unused-vars
              .then(result => {
                // console.log('logout:', result);
                if (from.path === '/') {
                  next({name: 'login'});
                } else {
                  next('/');
                }
              })
              .catch(error => {
                console.error('error logout:', error);
                next(from);
              });
          },
        },
  
        {
          path: 'acco',
          name: 'acco',
          beforeEnter() {
            location.href = 'http://acco.com/';
          }
        }
      ],
    },
    // {
    //   path: '/sales-channels',
    //   component: () => import('layouts/CommerceLayout'),
    //   children: [
    //     {
    //       path: '',
    //       name: 'sales-channels',
    //       component: () => import('pages/Commerce/SalesChannels')
    //     },
    //     {
    //       path: 'facebook-marketplace',
    //       name: 'facebook-marketplace',
    //       component: () => import('pages/Commerce/FacebookMarketplace')
    //     },
    //   ]
    // },
    {
      path: '/bank',
      component: () => import('components/banks/BankDashboard'),
    },
  ];

  // Always leave this as last one
  if (process.env.MODE !== 'ssr') {
    routes.push({
      path: '*',
      component: () => import('pages/Error404.vue'),
    });
  }

  return routes;
}

