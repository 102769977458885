import feathersClient, {makeServicePlugin, BaseModel} from '../../api/feathers-client';

import {diff} from '@iy4u/common-client-lib';

class Payments extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'Payments';

  static diffOnPatch(data) {
    console.log('diffOnPatch data', data);
    if (data['_id']) {
      const originalObject = Payments.store.state['payments'].keyedById[data['_id']];
      return diff(originalObject, data);
    } else {
      return data;
    }
  }

  // Define default properties here
  static instanceDefaults() {
    return {
      account: undefined,
      amount: 0,
      paidDate: undefined,
      status: undefined,
      memo: undefined,
      referenceNumber: undefined,

      // Quickbooks Fields:
      transferredToExternal: undefined,
      transferredToExternalDate: undefined,
      receivedFromExternal: undefined,
      receivedFromExternalDate: undefined,
    };
  }
}

const servicePath = 'payments';
const servicePlugin = makeServicePlugin({
  Model: Payments,
  service: feathersClient.service(servicePath),
  servicePath,
  state: {},
  getters: {},
  mutations: {},
  actions: {},
});

// const beforeHook = context => {
//   // eslint-disable-next-line no-console
//   console.log('------------->>>> beforeHook - context.method:', context.method);
//   console.log('------------->>>> beforeHook - context.params:', context.params);
//   console.log('------------->>>> beforeHook - context.data:', context.data);
// };

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [ctx => console.log(ctx)],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
