/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/

import 'quasar/dist/quasar.ie.polyfills.js'



import '@quasar/extras/roboto-font/roboto-font.css'

import '@quasar/extras/material-icons/material-icons.css'

import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'

import '@quasar/extras/material-icons-round/material-icons-round.css'

import '@quasar/extras/material-icons-sharp/material-icons-sharp.css'

import '@quasar/extras/mdi-v5/mdi-v5.css'

import '@quasar/extras/ionicons-v4/ionicons-v4.css'

import '@quasar/extras/eva-icons/eva-icons.css'

import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'

import '@quasar/extras/themify/themify.css'

import '@quasar/extras/line-awesome/line-awesome.css'



import '@quasar/extras/animate/fadeInDownBig.css'

import '@quasar/extras/animate/fadeOutDownBig.css'

import '@quasar/extras/animate/fadeIn.css'

import '@quasar/extras/animate/fadeOut.css'

import '@quasar/extras/animate/backInLeft.css'

import '@quasar/extras/animate/backOutRight.css'


// We load Quasar stylesheet file
import 'quasar/dist/quasar.sass'


// We add Quasar addons, if they were requested
import 'quasar/src/css/flex-addon.sass'



import 'src/css/app.scss'

import '@quasar/quasar-ui-qiconpicker/src/index.sass'


import Vue from 'vue'
import createApp from './app.js'




import qboot_Bootaxios from 'boot/axios'

import qboot_BootfeathersClient from 'boot/feathersClient'

import qboot_BootglobalMixins from 'boot/globalMixins'

import qboot_Booti18n from 'boot/i18n'

import qboot_BootIrRoles from 'boot/IrRoles'

import qboot_BootiyCommonLib from 'boot/iyCommonLib'

import qboot_Bootlodash from 'boot/lodash'

import qboot_BootVueCompositionApi from 'boot/VueCompositionApi'

import qboot_BootVueNestable from 'boot/VueNestable'

import qboot_BootVClipboard from 'boot/VClipboard'

import qboot_Bootdashblocks from 'boot/dashblocks'

import qboot_Ionrevquasarappextensionirauthmanagementclientsrcbootindexjs from '@ionrev/quasar-app-extension-ir-auth-management-client/src/boot/index.js'

import qboot_Ionrevquasarappextensionirformgenappsrcbootlodashjs from '@ionrev/quasar-app-extension-ir-form-gen-app/src/boot/lodash.js'

import qboot_IonrevquasarappextensionirformgenappsrcbootFormGeneratorjs from '@ionrev/quasar-app-extension-ir-form-gen-app/src/boot/FormGenerator.js'

import qboot_IonrevquasarappextensionirformgenappsrcbootJsonEditorjs from '@ionrev/quasar-app-extension-ir-form-gen-app/src/boot/JsonEditor.js'

import qboot_IonrevquasarappextensionirformgenappsrcbootFormGroupGeneratorjs from '@ionrev/quasar-app-extension-ir-form-gen-app/src/boot/FormGroupGenerator.js'

import qboot_IonrevquasarappextensionirformgenappsrcbootFormGroupGeneratorRecjs from '@ionrev/quasar-app-extension-ir-form-gen-app/src/boot/FormGroupGeneratorRec.js'

import qboot_IonrevquasarappextensionirformgenappsrcbootFormsetGeneratorjs from '@ionrev/quasar-app-extension-ir-form-gen-app/src/boot/FormsetGenerator.js'

import qboot_IonrevquasarappextensionirformgenappsrcbootLoginFormjs from '@ionrev/quasar-app-extension-ir-form-gen-app/src/boot/LoginForm.js'

import qboot_IonrevquasarappextensionirformgenappsrcbootRegisterFormjs from '@ionrev/quasar-app-extension-ir-form-gen-app/src/boot/RegisterForm.js'

import qboot_IonrevquasarappextensionirformgenappsrcbootVueCtkDateTimePickerjs from '@ionrev/quasar-app-extension-ir-form-gen-app/src/boot/VueCtkDateTimePicker.js'

import qboot_IonrevquasarappextensionirformgenappsrcbootvueTelInputjs from '@ionrev/quasar-app-extension-ir-form-gen-app/src/boot/vueTelInput.js'

import qboot_IonrevquasarappextensionirformgenappsrcbootArrayInsertjs from '@ionrev/quasar-app-extension-ir-form-gen-app/src/boot/ArrayInsert.js'

import qboot_IonrevquasarappextensionirformgenappsrcbootomitDeepjs from '@ionrev/quasar-app-extension-ir-form-gen-app/src/boot/omitDeep.js'

import qboot_IonrevquasarappextensionirformgenappsrcbootVueNumberInputjs from '@ionrev/quasar-app-extension-ir-form-gen-app/src/boot/VueNumberInput.js'

import qboot_Quasarquasarappextensionqiconpickersrcbootregisterjs from '@quasar/quasar-app-extension-qiconpicker/src/boot/register.js'







Vue.config.devtools = true
Vue.config.productionTip = false






const publicPath = `/`


async function start () {
  const { app, store, router } = await createApp()

  

  
  let hasRedirected = false
  const redirect = url => {
    hasRedirected = true
    const normalized = Object(url) === url
      ? router.resolve(url).route.fullPath
      : url

    window.location.href = normalized
  }

  const urlPath = window.location.href.replace(window.location.origin, '')
  const bootFiles = [qboot_Bootaxios,qboot_BootfeathersClient,qboot_BootglobalMixins,qboot_Booti18n,qboot_BootIrRoles,qboot_BootiyCommonLib,qboot_Bootlodash,qboot_BootVueCompositionApi,qboot_BootVueNestable,qboot_BootVClipboard,qboot_Bootdashblocks,qboot_Ionrevquasarappextensionirauthmanagementclientsrcbootindexjs,qboot_Ionrevquasarappextensionirformgenappsrcbootlodashjs,qboot_IonrevquasarappextensionirformgenappsrcbootFormGeneratorjs,qboot_IonrevquasarappextensionirformgenappsrcbootJsonEditorjs,qboot_IonrevquasarappextensionirformgenappsrcbootFormGroupGeneratorjs,qboot_IonrevquasarappextensionirformgenappsrcbootFormGroupGeneratorRecjs,qboot_IonrevquasarappextensionirformgenappsrcbootFormsetGeneratorjs,qboot_IonrevquasarappextensionirformgenappsrcbootLoginFormjs,qboot_IonrevquasarappextensionirformgenappsrcbootRegisterFormjs,qboot_IonrevquasarappextensionirformgenappsrcbootVueCtkDateTimePickerjs,qboot_IonrevquasarappextensionirformgenappsrcbootvueTelInputjs,qboot_IonrevquasarappextensionirformgenappsrcbootArrayInsertjs,qboot_IonrevquasarappextensionirformgenappsrcbootomitDeepjs,qboot_IonrevquasarappextensionirformgenappsrcbootVueNumberInputjs,qboot_Quasarquasarappextensionqiconpickersrcbootregisterjs]

  for (let i = 0; hasRedirected === false && i < bootFiles.length; i++) {
    if (typeof bootFiles[i] !== 'function') {
      continue
    }

    try {
      await bootFiles[i]({
        app,
        router,
        store,
        Vue,
        ssrContext: null,
        redirect,
        urlPath,
        publicPath
      })
    }
    catch (err) {
      if (err && err.url) {
        window.location.href = err.url
        return
      }

      console.error('[Quasar] boot error:', err)
      return
    }
  }

  if (hasRedirected === true) {
    return
  }
  

  

    

    

    
      new Vue(app)
    

    

    

  

}

start()
