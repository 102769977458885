import { render, staticRenderFns } from "./GroupLayoutDialog.vue?vue&type=template&id=3a343218&scoped=true&"
import script from "./GroupLayoutDialog.vue?vue&type=script&lang=js&"
export * from "./GroupLayoutDialog.vue?vue&type=script&lang=js&"
import style0 from "./GroupLayoutDialog.vue?vue&type=style&index=0&id=3a343218&prod&lang=scss&"
import style1 from "./_GroupLayoutDialog.scss?vue&type=style&index=1&id=3a343218&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a343218",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QFooter from 'quasar/src/components/footer/QFooter.js';
import QSpace from 'quasar/src/components/space/QSpace.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QDialog,QLayout,QHeader,QToolbar,QToolbarTitle,QPageContainer,QPage,QFooter,QSpace});qInstall(component, 'directives', {ClosePopup});
