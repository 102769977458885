<template>
  <div id="GroupFieldsCarousel">
    <pre v-text="$attrs"/>
  </div>
</template>

<script>
  export default {
    name: 'GroupFieldsCarousel',
  };
</script>

<style scoped lang="scss" src="./_GroupFieldsCarousel.scss">

</style>
