import Vue from 'vue';
import Vuex from 'vuex';
import {FeathersVuex} from 'feathers-vuex';
// import {LocalStorage, SessionStorage} from 'quasar';
// import EncryptDecrypt from '../utils/encrypt-decrypt';

import {plugins as rolesPlugins} from '@iy4u/roles-client-lib';

const {irRolesAbilities, irRolesRoles, irRolesRules} = rolesPlugins;
import {plugins as fpPlugins} from '@iy4u/vue-fingerprint';

import {plugins as commonServicePlugins} from '@iy4u/common-client-lib';

const {
  instances,
  vInstances,
  environments,
  domains,
  hosts,
  devices,
  features,
  applications,
  integrations,
  integrationAuths,
} = commonServicePlugins;


import auth from './store.auth';
import layout from './modules/layout';
// import example from './module-example'
// import axios from 'axios';


Vue.use(Vuex);
Vue.use(FeathersVuex);

const requireModule = require.context(
  // The path where the service modules live
  './services',
  // Whether to look in subfolders
  false,
  // Only include .js files (prevents duplicate imports`)
  /.js$/,
);
const servicePlugins = requireModule
  .keys()
  .map(modulePath => requireModule(modulePath).default);


/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default async function (/* { ssrContext } */) {
  console.log('test');
  const irRolesAbilitiesPlugin = await irRolesAbilities({
    FeathersClient: () => import('../api/feathers-client'),
  });
  const irRolesRolesPlugin = await irRolesRoles({
    FeathersClient: () => import('../api/feathers-client'),
  });
  const irRolesRulesplugin = await irRolesRules({
    FeathersClient: () => import('../api/feathers-client'),
  });

  const fingerprintPlugin = await fpPlugins.fingerprints({
    FeathersClient: () => import('../api/feathers-client'),
  });

  const myIpPlugin = await fpPlugins.myIp({
    FeathersClient: () => import('../api/feathers-client'),
  });

  const fingerprintAuthPlugin = await fpPlugins.fingerprintAuthService({
    FeathersClient: () => import('../api/feathers-client'),
    header: 'F-Authorization',
  });

  const instancesPlugin = await instances({
    FeathersClient: () => import('../api/feathers-client'),
  });
  const vInstancesPlugin = await vInstances({
    FeathersClient: () => import('../api/feathers-client'),
  });
  const domainsPlugin = await domains({
    FeathersClient: () => import('../api/feathers-client'),
  });

  const applicationsPlugin = await applications({
    FeathersClient: () => import('../api/feathers-client'),
  });

  const environmentsPlugin = await environments({
    FeathersClient: () => import('../api/feathers-client'),
  });

  const hostsPlugin = await hosts({
    FeathersClient: () => import('../api/feathers-client'),
  });

  const featuresPlugin = await features({
    FeathersClient: () => import('../api/feathers-client'),
  });

  const devicesPlugin = await devices({
    FeathersClient: () => import('../api/feathers-client'),
  });

  const integrationsPlugin = await integrations({
    FeathersClient: () => import('../api/feathers-client'),
  });

  const integrationAuthsPlugin = await integrationAuths({
    FeathersClient: () => import('../api/feathers-client'),
  });

  const Store = new Vuex.Store({
    state: {
      // fingerprint: undefined,
      // ipInfo: undefined
      activeAccount: {},
      defaultAccount: {},
      softAutomn: ['#2e180e', '#a77c52', '#f2dbc1', '#faf5ef', '#3e4625', '#ffd1d1', '#d3bd89', '#b3975a', '#9e7b36', '#cb8f74', '#ab6c50', '#b76874', '#dfa5a5', '#915054', '#682c2e', '#604a66', '#704a78', '#876a8d', '#345456', '#3e7670', '#6e9591', '#b1b999', '#7b8452', '#525640'],
      lightSpring: ['#eedcd8', '#e5d2d3', '#e6cfbd', '#d8c6ae', '#cbae8f', '#c9ae8e', '#e0bdb8', '#e7a49e', '#c6c2a6', '#958e7a', '#484448', '#efa4ba', '#ea588d', '#e72e77', '#e34976', '#d90d3a', '#01613f', '#309d32', '#66b258', '#88c979', '#aed384', '#3e1920', '#3d3238', '#5f545e', '#ada09e', '#cdc3c3', '#afaab4', '#61606a', '#373a4b', '#59412a', '#5b2b27', '#78cfdc', '#30b5c7', '#6bc7b8', '#149e9c', '#045b54', '#02607a', '#1599c0', '#64b2de', '#5b9ce4', '#83bbe7', '#d59e3b', '#e5be74', '#dab744', '#e7c667', '#e3d85b', '#000852', '#033681', '#0726a5', '#4260ba', '#789bd8', '#c51419', '#da3e42', '#e37267', '#e37250', '#e0a28c', '#df92a6', '#e07062', '#e06f4b', '#e09e8a', '#e093a7', '#de8895', '#d85b71', '#d5375a', '#d1194a', '#9490dc', '#665cc4', '#160f5c'],
    },
    getters: {
      getActiveAccount(state) {
        return state.activeAccount;
      },
      getDefaultAccount(state) {
        return state.defaultAccount;
      },
      // fingerprint(state){
      //   return state.fingerprint;
      // },
      // ipInfo(state){
      //   return state.ipInfo;
      // }
    },
    mutations: {
      SET_ACTIVE_ACCOUNT(state, payload) {
        Vue.set(state, 'activeAccount', payload);
      },
      SET_DEFAULT_ACCOUNT(state, payload) {
        Vue.set(state, 'defaultAccount', payload);
      },
      // GET_IP(state, payload){
      //   Vue.set(state, 'ipInfo', payload);
      // },
      // SET_FINGERPRINT(state, payload){
      //   if(payload) {
      //     let { _id, browser, os, platform, ipInfo: {ip, country, region, city, lngLat }} = payload;
      //     LocalStorage.set('fingerprintId', _id);
      //
      //     let quickPrint = {
      //       browser,
      //       os,
      //       platform,
      //       ip,
      //       country,
      //       region,
      //       city,
      //       lngLat,
      //       start: new Date()
      //     };
      //
      //    // let sessionPrint = EncryptDecrypt.encryptSymmetric(JSON.stringify(quickPrint), process.env.TRANSPORT_SECRET);
      //
      //    SessionStorage.set('sessionPrint', JSON.stringify(quickPrint));
      //   }
      //   Vue.set(state, 'fingerprint', payload);
      // }
    },
    actions: {
      setActiveAccount({ commit }, payload){
        commit('SET_ACTIVE_ACCOUNT', payload);
      },
      setDefaultAccount({ commit }, payload){
        commit('SET_DEFAULT_ACCOUNT', payload);
      },
      // getClientIp({commit}, params = {}) {
      //   axios.get(`${process.env.VUE_APP_FEATHERS_URL}/my-ip`,{params: params.query})
      //     .then(res => {
      //       console.log('ip address res', res);
      //       commit('GET_IP', res);
      //       return res;
      //     })
      //     .catch(err => {
      //       console.log('findCards err:', err);
      //       return undefined;
      //     });
      // },
      // setFingerprint({ commit }, payload){
      //   commit('SET_FINGERPRINT', payload);
      // }
    },

    modules: {
      layout,
      // example
    },
    plugins: [
      ...servicePlugins,
      irRolesAbilitiesPlugin,
      irRolesRolesPlugin,
      irRolesRulesplugin,
      auth,
      myIpPlugin,
      fingerprintPlugin,
      fingerprintAuthPlugin,
      instancesPlugin,
      vInstancesPlugin,
      environmentsPlugin,
      domainsPlugin,
      hostsPlugin,
      devicesPlugin,
      featuresPlugin,
      applicationsPlugin,
      integrationsPlugin,
      integrationAuthsPlugin,
    ],

    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: process.env.DEV,
  });

  return Store;
}
