// eslint-disable-next-line no-unused-vars
export default function ({store, ssrContext}) {
  const routes = [
    {
      path: '/',
      component: () => import('layouts/DistributionsLayout.vue'),
      children: [
        {
          path: '',
          name: 'home',
          component: () => import('pages/Distributions/Home.vue'),
        },
        {
          path: 'about',
          name: 'about',
          component: () => import('pages/Distributions/About.vue'),
        },
        {
          path: 'leadership-team',
          name: 'leadership-team',
          component: () => import('pages/Distributions/LeadershipTeam.vue'),
        },
        {
          path: 'values',
          name: 'values',
          component: () => import('pages/Distributions/Values.vue'),
        },
        {
          path: 'careers',
          name: 'careers',
          component: () => import('pages/Distributions/Careers.vue'),
        },
        {
          path: 'fulfillment-services',
          name: 'fulfillment-services',
          component: () => import('pages/Distributions/FulfillmentServices.vue'),
        },
        {
          path: 'contact',
          name: 'contact',
          component: () => import('pages/Distributions/Contact.vue'),
        },
        {
          path: 'brands',
          name: 'brands',
          component: () => import('pages/Distributions/Brands.vue'),
        },
  
        {
          path: 'coming-soon',
          name: 'coming-soon',
          component: () => import('pages/ComingSoonPage/ComingSoonPage'),
        },

        // {
        //   path: 'login',
        //   name: 'login',
        //   // eslint-disable-next-line no-unused-vars
        //   props(route) {
        //     return {
        //       attrs: {
        //         fieldsColor: 'secondary',
        //         redirectUrl: {
        //           name: 'home',
        //         },
        //         'login-btn-attrs': {
        //           class: ['shadow-5'],
        //           color: 'primary',
        //           'no-caps': false,
        //         },
        //         'forgot-password-attrs': {
        //           'btn-attrs': {
        //             color: 'accent',
        //             'no-caps': false,
        //           },
        //         },
        //         'title-attrs': {
        //           class: 'text-uppercase',
        //           style: 'font-size: 25px; font-weight: 700;',
        //         },
        //       },
        //       'page-attrs': {
        //         style: {
        //           background: 'radial-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .5))',
        //           'background-size': 'cover',
        //           '-o-background-size': 'cover',
        //           '-moz-background-size': 'cover',
        //           '-webkit-background-size': 'cover',
        //         },
        //       },
        //     };
        //   },
        //   component: () => import('@ionrev/quasar-app-extension-ir-auth-management-client/src/pages/login/baseLogin/login'),
        // },
        // {
        //   path: 'register',
        //   name: 'register',
        //   // eslint-disable-next-line no-unused-vars
        //   props(route) {
        //     return {
        //       attrs: {
        //         fieldsColor: 'secondary',
        //         default_verify_value: ['sms'],
        //         'title-attrs': {
        //           class: 'text-uppercase',
        //           style: 'font-size: 25px; font-weight: 700;',
        //         },
        //         'register-btn-attrs': {
        //           class: ['shadow-5'],
        //           color: 'primary',
        //           'no-caps': false,
        //         },
        //         'verify-dialog-title-attrs': {
        //           class: 'bg-primary text-accent text-uppercase q-mb-sm',
        //         },
        //         'sentToMessage-attrs': {
        //           class: 'text-weight-bold text-accent',
        //           style: 'display: inline-block;',
        //         },
        //         'sentToMessage-div-attrs': {
        //           class: 'text-uppercase',
        //           style: 'font-size: 25px;',
        //         },
        //       },
        //       'page-attrs': {
        //         style: {
        //           background: 'radial-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .5))',
        //           'background-size': 'cover',
        //           '-o-background-size': 'cover',
        //           '-moz-background-size': 'cover',
        //           '-webkit-background-size': 'cover',
        //         },
        //       },
        //     };
        //   },
        //   component: () => import('@ionrev/quasar-app-extension-ir-auth-management-client/src/pages/register/baseRegister/register'),
        // },
        // {
        //   path: 'verify',
        //   name: 'verify',
        //   component: () => import('@ionrev/quasar-app-extension-ir-auth-management-client/src/pages/authManagement/verifyEmail/verifyEmail'),
        // },
        // {
        //   path: 'resetPassword',
        //   name: 'resetPassword',
        //   component: () => import('@ionrev/quasar-app-extension-ir-auth-management-client/src/pages/authManagement/resetPassword/resetPassword'),
        // },
        // {
        //   path: 'verifyAndSetPassword',
        //   name: 'verifyAndSetPassword',
        //   component: () => import('@ionrev/quasar-app-extension-ir-auth-management-client/src/pages/authManagement/verifyAndSetPassword/verifyAndSetPassword'),
        // },
        // {
        //   path: 'changePassword',
        //   name: 'changePassword',
        //   component: () => import('@ionrev/quasar-app-extension-ir-auth-management-client/src/pages/authManagement/changePassword/changePassword'),
        // },
        //
        // {
        //   path: 'logout',
        //   name: 'logout',
        //   beforeEnter(to, from, next) {
        //     store.dispatch('auth/logout')
        //       // eslint-disable-next-line no-unused-vars
        //       .then(result => {
        //         // console.log('logout:', result);
        //         if (from.path === '/') {
        //           next({name: 'login'});
        //         } else {
        //           next('/');
        //         }
        //       })
        //       .catch(error => {
        //         console.error('error logout:', error);
        //         next(from);
        //       });
        //   },
        // },
      ],
    },
    {
      path: '/bank',
     component: () => import('components/banks/BankDashboard'),
    }
  ];

  // Always leave this as last one
  if (process.env.MODE !== 'ssr') {
    routes.push({
      path: '*',
      component: () => import('pages/Error404.vue'),
    });
  }

  return routes;
}

