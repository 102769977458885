import { render, staticRenderFns } from "./SelectOrAdd.vue?vue&type=template&id=5ff206a7&scoped=true&"
import script from "./SelectOrAdd.vue?vue&type=script&lang=js&"
export * from "./SelectOrAdd.vue?vue&type=script&lang=js&"
import style0 from "./SelectOrAdd.vue?vue&type=style&index=0&id=5ff206a7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ff206a7",
  null
  
)

export default component.exports
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QLinearProgress,QSpace,QItem,QItemSection,QAvatar,QImg,QItemLabel,QBtn,QSelect,QDialog,QCard,QCardSection,QCardActions});qInstall(component, 'directives', {Ripple});
