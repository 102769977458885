<template>
  <div id="q-app">
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
  import {colors} from 'quasar';

  import testHostStr from 'src/utils/testHostStr';

  export default {
    name: 'App',
    data() {
      return {};
    },

    meta() {
      const host = window.location.host;
      let config = process.env.SITES || {};
      if (testHostStr(['automation'], config['AUTOMATION_SITE_DOMAIN'], host) || config['FIXED_SITE_DOMAIN'] === 'automation') {
        return {
          title: 'BYLD Automation',
          link: {
            icon: {
              rel: 'icon',
              type: 'image/png',
              href: require('assets/logos/byldAutomation_logo_dark.svg'),
            }
          }
        };
      } else if (testHostStr(['commerce'], config['COMMERCE_SITE_DOMAIN'], host) || config['FIXED_SITE_DOMAIN'] === 'commerce') {
        return {
          title: 'BYLD Commerce',
          link: {
            icon: {
              rel: 'icon',
              type: 'image/png',
              href: require('assets/logos/byldcapital_logo_3_lightGrey.svg'),
            }
          }
        };
      } else if (testHostStr(['distributions'], config['DISTRIBUTIONS_SITE_DOMAIN'], host) || config['FIXED_SITE_DOMAIN'] === 'distributions') {
        return {
          title: 'BYLD Distributions',
          link: {
            icon: {
              rel: 'icon',
              type: 'image/png',
              href: require('assets/logos/byldDistribution_logo_dark.svg'),
            }
          }
        };
      }
    },

    mounted() {
      // depending on host, change the color scheme
      this.$removeAllCssVars();

      const host = window.location.host;

      let config = process.env.SITES || {};
      if (testHostStr(['commerce'], config['COMMERCE_SITE_DOMAIN'], host) || config['FIXED_SITE_DOMAIN'] === 'commerce') {
        let theme = {
          '--q-color-primary': '#547181',
          '--q-color-secondary': '#C02032',
          '--q-color-accent': '#879BCB',
        };
        this.setTheme(theme);
      } else if (testHostStr(['distributions'], config['DISTRIBUTIONS_SITE_DOMAIN'], host) || config['FIXED_SITE_DOMAIN'] === 'distributions') {
        let theme = {
          '--q-color-primary': '#4959A8',
          '--q-color-secondary': '#92683A',
          '--q-color-accent': '#879BCB',
        };
        this.setTheme(theme);
      } else if (testHostStr(['automation'], config['AUTOMATION_SITE_DOMAIN'], host) || config['FIXED_SITE_DOMAIN'] === 'automation') {
        let theme = {
          '--q-color-primary': '#005686',
          '--q-color-secondary': '#6FAD85',
          '--q-color-accent': '#AD716F',
        };
        this.setTheme(theme);
      }
    },

    watch: {
      $authUser: {
        immediate: true,
        deep: true,
        handler(newVal) {
          // console.log('user', newVal);
          // this.$removeAllCssVars();
          //
          // let theme = this.$lget(newVal, 'settings.theme');
          // if (theme) {
          //   Object.keys(theme).forEach(k => {
          //     if (k.startsWith('--') && theme[k]) {
          //       this.$setCssVar(k, theme[k]);
          //       let rgb = colors.textToRgb(theme[k]);
          //       let rgbString = `${rgb.r},${rgb.g},${rgb.b}`;
          //       this.$setCssVar(`${k}-rgb`, rgbString);
          //     }
          //   });
          // }

          let darkMode = this.$lget(newVal, 'settings.theme.darkMode', undefined);
          if (!this.$lisNil(darkMode)) {
            this.$q.dark.set(darkMode);
          } else {
            this.$q.dark.set(false);
          }
        }
      },
    },
    methods: {
      setTheme(theme) {
        this.$removeAllCssVars();

        if (theme) {
          Object.keys(theme).forEach(k => {
            if (k.startsWith('--') && theme[k]) {
              this.$setCssVar(k, theme[k]);
              let rgb = colors.textToRgb(theme[k]);
              let rgbString = `${rgb.r},${rgb.g},${rgb.b}`;
              this.$setCssVar(`${k}-rgb`, rgbString);
            }
          });
        }
      },
    }

  };
</script>
