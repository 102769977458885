import { render, staticRenderFns } from "./PlacesAutoComplete.vue?vue&type=template&id=fafa0f06&scoped=true&"
import script from "./PlacesAutoComplete.vue?vue&type=script&lang=js&"
export * from "./PlacesAutoComplete.vue?vue&type=script&lang=js&"
import style0 from "./_PlacesAutoComplete.scss?vue&type=style&index=0&id=fafa0f06&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fafa0f06",
  null
  
)

export default component.exports
import QForm from 'quasar/src/components/form/QForm.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QForm,QSelect,QItem,QItemSection});
